<template>
  <div>
    <span class="label">
      {{ label }}
    </span>
    <el-autocomplete
      style="width: 100%"
      size="mini"
      v-model="model"
      :disabled="disabled"
      :placeholder="placeholder"
      :fetch-suggestions="search"
      :trigger-on-focus="false"
      value-key="name"
      @focus="onFocus"
      @change="$emit('input', resultedValue); closePopup()"
      @select="$emit('input', resultedValue); closePopup()"
    >
      <template slot-scope="{ item }">
        {{ item.name }}
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'HouseField',
  inject: ['getPreviousValue'],

  props: {
    label: {
      type: String
    },
    type: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: Object | String
    },
    addressType: {
      type: String,
      default: 'administrative'
    }
  },

  data () {
    return {
      model: null,
      options: [],
      isLoaded: false
    }
  },

  computed: {
    resultedValue () {
      if (!this.model) {
        return null
      }
      let object = {}
      let issetHouse = this.options.find((item) => item.name === this.model)
      if (issetHouse) {
        object.id = issetHouse.id
        this.$emit('set-is_added_address', issetHouse.is_added_address)
      }
      object.name = this.model

      object.previous = this.previousValue.id
      this.$emit('house-changed', object)
      return object
    },
    disabled () {
      return !((this.type === 'subject') || this.previousValue)
    },
    previousValue () {
      return this.getPreviousValue(this.type)
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (value && value.id && this.options.findIndex((item) => item.id === value.id) === -1) {
          this.options.push(value)
        }
        let a = value
        if (a !== null) {
          if (value.name) {
            a = value.name
          }
        }
        this.model = a
        //this.model = value && value.name ? value.name : null

        this.$emit('input', this.resultedValue)
      }
    },
    previousValue () {
      this.$emit('input', null)
      this.model = null
      this.isLoaded = false
    }
  },

  methods: {
    async load () {
      // let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/houses/${this.previousValue.guid}/house/`)
      let data = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${this.previousValue.id}/${this.addressType}`)
      //fiasData = await this.$http.get(`${this.$config.api}/garaddressservice/addresses/houses/${this.previousValue.guid}/${this.addressType}`, { params })
      this.options = data.data.map((item) => {
        return item
        return {
          //house_houseid: item.house_houseid,
          //house: item.name

        }
      })
      this.isLoaded = true
    },
    onFocus () {
      if (!this.isLoaded) {
        this.load()
      }
    },
    async search (query, callback) {
      //let results = query ? this.options.filter((item) => item.house.toLowerCase().indexOf(query.toLowerCase()) === 0) : this.options
      let results = query ? this.options.filter((item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) : this.options
      callback(results)
      results.length > 0 && this.$emit('open-popup')
    },
    closePopup () {
      // Задержка из-за v-clickoutside в AddressObjects
      setTimeout(() => this.$emit('close-popup'), 200)
    }
  }
}
</script>

<style scoped>
.label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #7D7D7D;
  display: block;
  padding: 5px 5px 0px 5px;
}
</style>
